import { request } from '@/libs/index';

function resetPasswordRequest(
  { email, password, token, inviteBy, inviteByEmail } = { email: '', password: '', token: '', inviteBy: '', inviteByEmail: '' },
) {
  return request({
    method: 'POST',
    url: '/reset',
    data: {
      username: email,
      password: password,
      password_reset_token: token,
      inviteBy: inviteBy,
      inviteByEmail: inviteByEmail,
    },
  }).catch((e) => Promise.reject(e));
}

export { resetPasswordRequest as default };
